<template>
  <div class="activity-block">
    <menu-breadcrumb>
      <el-breadcrumb-item>活動管理</el-breadcrumb-item>
    </menu-breadcrumb>

    <div class="container">
      <table-title>
        <p>
          活動列表 <span class="unit">數量：{{ total }}</span>
        </p>

        <div>
          <el-button
            type="success"
            @click="$router.push({ name: 'CreateActivity' })"
            >新增活動
          </el-button>
        </div>
      </table-title>

      <el-table :data="activity_data">
        <el-table-column prop="published" width="80" label="發佈" sortable>
          <template #default="{ row }">
            <el-checkbox
              :value="row.published"
              @change="handlePublished(row.activity_id)"
            ></el-checkbox>
          </template>
        </el-table-column>
        <el-table-column
          prop="title_zh"
          label="中文標題"
          sortable
        ></el-table-column>
        <el-table-column
          prop="title_en"
          label="英文標題"
          sortable
        ></el-table-column>
        <el-table-column
          prop="start_date"
          label="報名起始時間"
          width="200"
          sortable
        >
          <template #default="{ row }">
            {{ $formatTime(row.start_date) }}
          </template>
        </el-table-column>
        <el-table-column
          prop="end_date"
          label="報名結束時間"
          width="200"
          sortable
        >
          <template #default="{ row }">
            {{ $formatTime(row.end_date) }}
          </template>
        </el-table-column>
        <el-table-column fixed="right" width="260">
          <template #header="{}">
            <el-input
              v-model="setup.search"
              size="mini"
              placeholder="搜尋活動標題"
              @keyup.enter.native="handleSearch"
            >
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="handleSearch"
              ></el-button>
            </el-input>
          </template>

          <template #default="{ row }">
            <el-button
              size="mini"
              type="primary"
              icon="el-icon-setting"
              @click="
                $router.push({
                  name: 'DetailActivity',
                  params: { id: row.activity_id },
                })
              "
              >內容</el-button
            >
            <el-button
              size="mini"
              type="warning"
              icon="el-icon-edit"
              @click="
                $router.push({
                  name: 'EditActivity',
                  params: { id: row.activity_id },
                })
              "
              >編輯</el-button
            >
            <el-button
              size="mini"
              type="danger"
              icon="el-icon-delete"
              @click="handleDelete(row.activity_id)"
              >刪除</el-button
            >
          </template>
        </el-table-column>
      </el-table>

      <table-pagination
        :total="total"
        :limit="setup.limit"
        :page="setup.page"
        @change-page="handleChangePage"
      ></table-pagination>
    </div>
  </div>
</template>

<script>
import {
  deleteActivity,
  getActivitiesPagination,
  updateActivity,
} from "@/api/activity";
import dayjs from "dayjs";

export default {
  name: "Activity",
  data() {
    return {
      activity_data: [],
      total: 0,
      setup: {
        page: 0,
        limit: 25,
        search: "",
      },
    };
  },
  created() {
    const { p, s } = this.$route.query;
    if (p) {
      this.setup.page = +p;
    }
    if (s) {
      this.setup.search = decodeURIComponent(s);
    }
    this.handleGetActivities();
  },
  methods: {
    async handleGetActivities() {
      const { results, total } = await getActivitiesPagination(this.setup);

      this.activity_data = results;
      this.total = total;
    },
    handleChangePage(page) {
      this.setup.page = page;

      this.handleGetActivities();
    },
    async handleSearch() {
      this.setup.page = 0;

      let query = {
        p: this.setup.page,
        s: encodeURIComponent(this.setup.search),
      };

      if (decodeURIComponent(query.s).length === 0) delete query.s;

      await this.$router.push({ query });

      await this.handleGetActivities();
    },
    handleDelete(activity_id) {
      this.$confirm("是否刪除該活動", "警告", {
        confirmButtonText: "確定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          await deleteActivity(activity_id);
          await this.handleGetActivities();

          this.$message({
            type: "success",
            message: "刪除成功",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "刪除取消",
          });
        });
    },
    handleAdjustActivity(activity) {
      const cloneActivity = JSON.parse(JSON.stringify(activity));

      cloneActivity.published = !cloneActivity.published;

      if (cloneActivity.category) {
        cloneActivity.category_id = activity.category.category_id;
      }
      delete cloneActivity.category;
      if (cloneActivity.subcategory) {
        cloneActivity.subcategory_id = activity.subcategory.subcategory_id;
      }
      delete cloneActivity.subcategory;

      const formData = new FormData();

      Object.keys(cloneActivity).forEach((key) => {
        if (key === "photo") {
          if (cloneActivity[key] instanceof File) {
            formData.append("photo_file", cloneActivity[key]);
            return;
          }
          return;
        }

        if (key === "start_date" || key === "end_date") {
          // transform date to ISOString
          formData.append(key, dayjs(cloneActivity[key]).toISOString());
          return;
        }

        if (key === "activity_sessions") {
          formData.append(
            "activity_sessions",
            JSON.stringify(cloneActivity[key])
          );
          return;
        }

        if (key.startsWith("question")) {
          formData.append(key, JSON.stringify(cloneActivity[key]));
          return;
        }

        formData.append(key, cloneActivity[key]);
      });

      return formData;
    },
    async handlePublished(activity_id) {
      const activity = this.activity_data.find(
        (activity) => activity.activity_id === activity_id
      );

      const formData = this.handleAdjustActivity(activity);

      try {
        await updateActivity(activity_id, formData);

        this.$message({
          type: "success",
          message: activity.published ? "下架成功" : "上架成功",
        });

        activity.published = !activity.published;
      } catch (error) {
        this.$message({
          type: "error",
          message: "發佈失敗",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
