import request from "@/utils/request";

/**
 * Get activities.
 *
 * @param {object} [params]
 * @param {string} [params.search]
 */
export function getActivities(params) {
  return request({
    url: "api/v1/activities",
    method: "GET",
    params,
  });
}

/**
 * Get activities with pagination and search features.
 *
 * @param {object} params
 * @param {number} params.limit
 * @param {number} params.page
 * @param {string} [params.search]
 */
export function getActivitiesPagination(params) {
  return request({
    url: "api/v1/activities/page",
    method: "GET",
    params,
  });
}

/**
 * Get activity by id.
 *
 * @param {string} activity_id
 * @param {object} params
 * @param {object} params.relation
 */
export function getActivity(activity_id, params) {
  return request({
    url: `api/v1/activities/${activity_id}`,
    method: "GET",
    params,
  });
}

/**
 * Create activity.
 *
 * @param {object} data
 * @param {File} data.photo
 * @param {string} data.start_date
 * @param {string} data.end_date
 * @param {string} data.title_zh
 * @param {string} data.title_en
 * @param {string} data.introduction_zh
 * @param {string} data.introduction_en
 * @param {string} data.location_zh
 * @param {string} data.location_en
 * @param {number} data.category_id
 * @param {boolean} data.live
 * @param {string} data.live_stream_url
 * @param {number} data.category_id
 * @param {number} [data.subcategory_id]
 * @param {string} [data.registration_url]
 * @param {string} data.final_project_url
 * @param {number} data.max_participant
 * @param {string} data.question_1
 * @param {string} data.question_2
 * @param {string} data.question_3
 * @param {boolean} data.question_1_required
 * @param {boolean} data.question_2_required
 * @param {boolean} data.question_3_required
 * @param {string} data.comment
 * @param {string} data.contact_email
 * @param {boolean} data.published
 * @param {string} data.activity_sessions
 */
export function createActivity(data) {
  return request({
    url: "api/v1/activities",
    method: "POST",
    data,
    headers: { "Content-Type": "multipart/form-data" },
  });
}

/**
 * Update activity.
 *
 * @param {number} activity_id
 * @param {object} data
 * @param {File} [data.photo]
 * @param {string} data.start_date
 * @param {string} data.end_date
 * @param {string} data.title_zh
 * @param {string} data.title_en
 * @param {string} data.introduction_zh
 * @param {string} data.introduction_en
 * @param {string} data.location_zh
 * @param {string} data.location_en
 * @param {number} data.category_id
 * @param {boolean} data.live
 * @param {string} data.live_stream_url
 * @param {number} data.category_id
 * @param {number} [data.subcategory_id]
 * @param {string} [data.registration_url]
 * @param {string} data.final_project_url
 * @param {number} data.max_participant
 * @param {string} data.question_1
 * @param {string} data.question_2
 * @param {string} data.question_3
 * @param {boolean} data.question_1_required
 * @param {boolean} data.question_2_required
 * @param {boolean} data.question_3_required
 * @param {string} data.comment
 * @param {string} data.contact_email
 * @param {boolean} data.published
 * @param {string} data.activity_sessions
 */
export function updateActivity(activity_id, data) {
  return request({
    url: `api/v1/activities/${activity_id}`,
    method: "PATCH",
    data,
    headers: { "Content-Type": "multipart/form-data" },
  });
}

/**
 * Delete activity.
 *
 * @param {string} activity_id
 */
export function deleteActivity(activity_id) {
  return request({
    url: `api/v1/activities/${activity_id}`,
    method: "DELETE",
  });
}

/**
 * Create activity speaker.
 *
 * @param {string} activity_id
 * @param {number} speaker_id
 */
export function createActivitySpeaker(activity_id, speaker_id) {
  return request({
    url: `api/v1/activities/${activity_id}/speakers/${speaker_id}`,
    method: "POST",
  });
}

/**
 * Delete activity speaker.
 *
 * @param {string} activity_id
 * @param {number} speaker_id
 */
export function deleteActivitySpeaker(activity_id, speaker_id) {
  return request({
    url: `api/v1/activities/${activity_id}/speakers/${speaker_id}`,
    method: "DELETE",
  });
}

/**
 * Get activity and keyword relationship.
 *
 * @param {string} activity_id
 * @param {object} params
 * @param {number} params.page
 * @param {number} params.limit
 * @param {string} params.search
 */
export function getActivityKeywords(activity_id, params) {
  return request({
    url: `api/v1/activities/${activity_id}/keywords`,
    method: "GET",
    params,
  });
}

/**
 * Create activity and keyword relationship.
 *
 * @param {string} activity_id
 * @param {object} data
 * @param {number[]} data.keyword_id_group
 */
export function createActivityKeywords(activity_id, data) {
  return request({
    url: `api/v1/activities/${activity_id}/keywords`,
    method: "POST",
    data,
  });
}

/**
 * Delete activity and keyword relationship.
 *
 * @param {string} activity_id
 * @param {number} keyword_id
 */
export function deleteActivityKeywords(activity_id, keyword_id) {
  return request({
    url: `api/v1/activities/${activity_id}/keywords/${keyword_id}`,
    method: "DELETE",
  });
}
