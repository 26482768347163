var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"activity-block"},[_c('menu-breadcrumb',[_c('el-breadcrumb-item',[_vm._v("活動管理")])],1),_c('div',{staticClass:"container"},[_c('table-title',[_c('p',[_vm._v(" 活動列表 "),_c('span',{staticClass:"unit"},[_vm._v("數量："+_vm._s(_vm.total))])]),_c('div',[_c('el-button',{attrs:{"type":"success"},on:{"click":function($event){return _vm.$router.push({ name: 'CreateActivity' })}}},[_vm._v("新增活動 ")])],1)]),_c('el-table',{attrs:{"data":_vm.activity_data}},[_c('el-table-column',{attrs:{"prop":"published","width":"80","label":"發佈","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-checkbox',{attrs:{"value":row.published},on:{"change":function($event){return _vm.handlePublished(row.activity_id)}}})]}}])}),_c('el-table-column',{attrs:{"prop":"title_zh","label":"中文標題","sortable":""}}),_c('el-table-column',{attrs:{"prop":"title_en","label":"英文標題","sortable":""}}),_c('el-table-column',{attrs:{"prop":"start_date","label":"報名起始時間","width":"200","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.$formatTime(row.start_date))+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"end_date","label":"報名結束時間","width":"200","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.$formatTime(row.end_date))+" ")]}}])}),_c('el-table-column',{attrs:{"fixed":"right","width":"260"},scopedSlots:_vm._u([{key:"header",fn:function(ref){return [_c('el-input',{attrs:{"size":"mini","placeholder":"搜尋活動標題"},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.handleSearch.apply(null, arguments)}},model:{value:(_vm.setup.search),callback:function ($$v) {_vm.$set(_vm.setup, "search", $$v)},expression:"setup.search"}},[_c('el-button',{attrs:{"slot":"append","icon":"el-icon-search"},on:{"click":_vm.handleSearch},slot:"append"})],1)]}},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-button',{attrs:{"size":"mini","type":"primary","icon":"el-icon-setting"},on:{"click":function($event){return _vm.$router.push({
                name: 'DetailActivity',
                params: { id: row.activity_id },
              })}}},[_vm._v("內容")]),_c('el-button',{attrs:{"size":"mini","type":"warning","icon":"el-icon-edit"},on:{"click":function($event){return _vm.$router.push({
                name: 'EditActivity',
                params: { id: row.activity_id },
              })}}},[_vm._v("編輯")]),_c('el-button',{attrs:{"size":"mini","type":"danger","icon":"el-icon-delete"},on:{"click":function($event){return _vm.handleDelete(row.activity_id)}}},[_vm._v("刪除")])]}}])})],1),_c('table-pagination',{attrs:{"total":_vm.total,"limit":_vm.setup.limit,"page":_vm.setup.page},on:{"change-page":_vm.handleChangePage}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }